var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container PostOfferComplete gnb-h-110" },
    [
      _vm.step === 0
        ? [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center align-items-center h-80"
              },
              [
                _c("div", { staticClass: "PostOfferComplete-notice" }, [
                  _c("div", { staticClass: "PostOfferComplete-noticeTitle" }, [
                    _vm._v("견적을 요청했습니다!")
                  ]),
                  _vm._m(0),
                  _c("img", {
                    attrs: {
                      src: _vm.$lib.imgUrl("img/offer/post-offer-laststep.jpg"),
                      width: "300px"
                    }
                  })
                ])
              ]
            ),
            _c("footer-box", {
              attrs: {
                submitText: "확인",
                submitCb: function() {
                  return _vm.nextStep()
                }
              }
            })
          ]
        : _vm.step === 1
        ? [
            _c("div", {
              staticClass: "PostOfferComplete-headerSubtitle",
              domProps: {
                innerHTML: _vm._s(_vm.completePageData.headerSubtitle)
              }
            }),
            _c("div", {
              staticClass: "PostOfferComplete-headerBody",
              domProps: { innerHTML: _vm._s(_vm.completePageData.headerBody) }
            }),
            _c("div", {
              staticClass: "PostOfferComplete-recommArtistSummary",
              domProps: {
                innerHTML: _vm._s(_vm.completePageData.recommArtistSummary)
              }
            }),
            _vm.completePageData.youtubeId
              ? _c(
                  "div",
                  { staticClass: "PostOfferComplete-youtubeId" },
                  [
                    _c("youtube", {
                      style: {
                        width: "480px",
                        height: "270px"
                      },
                      attrs: {
                        "video-id": _vm.completePageData.youtubeId,
                        "player-vars": { rel: 0, showinfo: 0, controls: 0 }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", {
              staticClass: "ui-border-line ui-h-0",
              staticStyle: { "margin-bottom": "60px" }
            }),
            _c("footer-box", {
              attrs: {
                single: false,
                btnText: "닫기",
                btnCb: function() {
                  return _vm.submit()
                },
                submitText: "지금 알아보기",
                submitCb: function() {
                  return _vm.nextStep()
                }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "PostOfferComplete-noticeBody" }, [
      _c("span", { staticClass: "color-lavender" }, [
        _vm._v("견적이 도착하면 실시간")
      ]),
      _vm._v("으로 알려드릴게요."),
      _c("br"),
      _c("br"),
      _c("span", { staticClass: "color-gray" }, [
        _vm._v("* 휴일 및 늦은 시간에는"),
        _c("br"),
        _vm._v("견적 도착이 늦을 수 있어요.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }