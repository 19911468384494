<template>
    <div class="container PostOfferComplete gnb-h-110">
        <template v-if="step === 0">
            <div class="d-flex justify-content-center align-items-center h-80">
                <div class="PostOfferComplete-notice">
                    <div class="PostOfferComplete-noticeTitle">견적을 요청했습니다!</div>
                    <div class="PostOfferComplete-noticeBody">
                        <span class="color-lavender">견적이 도착하면 실시간</span>으로 알려드릴게요.<br>
                        <br>
                        <span class="color-gray">* 휴일 및 늦은 시간에는<br>견적 도착이 늦을 수 있어요.</span>
                    </div>
                    <img :src="$lib.imgUrl('img/offer/post-offer-laststep.jpg')" width="300px" />
                </div>
            </div>
            <footer-box
                submitText="확인"
                :submitCb="() => nextStep()"
            />
        </template>
        <template v-else-if="step === 1">
            <div class="PostOfferComplete-headerSubtitle" v-html="completePageData.headerSubtitle" />
            <div class="PostOfferComplete-headerBody" v-html="completePageData.headerBody" />
            <div class="PostOfferComplete-recommArtistSummary" v-html="completePageData.recommArtistSummary" />
            <div v-if="completePageData.youtubeId" class="PostOfferComplete-youtubeId">
                <youtube
                    :video-id="completePageData.youtubeId"
                    :player-vars="{ rel: 0, showinfo: 0, controls: 0 }"
                    :style="{
                        width: '480px',
                        height: '270px',
                    }"
                />
            </div>
            <div class="ui-border-line ui-h-0" style="margin-bottom: 60px" />
            <footer-box
                :single="false"
                btnText="닫기"
                :btnCb="() => submit()"
                submitText="지금 알아보기"
                :submitCb="() => nextStep()"
            />
        </template>
    </div>
</template>

<script>
import { useCacheStore } from '@/pinia/modules/cache'
import { t_genre_reverse, validGenre } from '@/lib/globalLib'

import FooterBox from '@/components/common/FooterBox'

type Props = {
    priceEnd: number,
    genre: string,
    eventType: string,
}

const isDev = process.env.NODE_ENV !== 'production'

const completePageDatas = [
    {
        condition: (props: Props = {}) => {
            return (
                true
                && props.genre === '노래'
                && /^(결혼식|주례없는 결혼식)$/.test(props.eventType)
                && (props.priceEnd == 300000 || props.priceEnd == 350000)
            )
        },
        data: (props: Props = {}) => ({
            gnbTitle: '추천상품',
            headerSubtitle: '최대 예산에서',
            headerBody: `<i class="color-lavender">${(390000 - props.priceEnd) / 10000}만원</i>만 추가하면\n<i class="color-lavender">2명이 5곡을</i> 불러주는\n뮤지컬웨딩이 가능해요!`,
            recommArtistSummary: `지금 바로\n합리적인 금액의 뮤지컬웨딩을 알아보세요!`,
            youtubeId: '94BkBOYrAzk',
            nextRoute: `/artist/profile/${isDev ? 131 : 7862}`,
        }),
    },
    {
        condition: (props: Props = {}) => {
            return (
                true
                && props.genre === '노래'
                && /^(결혼식|주례없는 결혼식)$/.test(props.eventType)
                && props.priceEnd == 500000
            )
        },
        data: () => ({
            gnbTitle: '추천상품',
            headerSubtitle: '최대 예산',
            headerBody: `<i class="color-lavender">50만원</i>이면\n2명이 5곡을 불러주는\n<i class="color-lavender">뮤지컬웨딩과 사회까지</i> 한번에!`,
            recommArtistSummary: `같은 비용으로 뮤지컬웨딩과 사회자를\n한번에 섭외하는건 어떠세요?`,
            youtubeId: '94BkBOYrAzk',
            nextRoute: `/artist/profile/${isDev ? 131 : 7880}`,
        }),
    },
    {
        condition: (props: Props = {}) => {
            return (
                true
                && props.genre === 'MC'
                && /^결혼식$/.test(props.eventType)
            )
        },
        data: () => ({
            gnbTitle: '추천상품',
            headerSubtitle: '',
            headerBody: `<i class="color-lavender">주례</i>는 알아보셨나요?\n<i class="color-lavender">대기업 출신, 현직 CEO들의</i>\n품격 있는 주례를 만나보세요.`,
            recommArtistSummary: `100회 이상의 안정적인 주례,\n지금 바로 알아보세요.`,
            youtubeId: 'lOwl5OWG36I',
            nextRoute: {
                path: '/artist/list',
                query: {
                    adminMemo: '주례',
                    disableResetBackHistory: 'true',
                    hiddenSearchConditionController: 'true',
                    resetDataOnBack: 'true',
                    sort: 'view',
                },
            },
        }),
    },
]

export default {
    components: {
        FooterBox,
    },
    data() {
        return {
            postedOfferData: {},
            step: 0,
        }
    },
    computed: {
        isDev() {
            return isDev
        },
        completePageData: {
            get() {
                return completePageDatas.find(data => data.condition(this.postedOfferData))?.data(this.postedOfferData)
            },
            cache: false,
        },
    },
    watch: {
        step() {
            this.setGnb()
        },
    },
    mounted() {
        this.init()
    },
    beforeRouteLeave(to, from, next) {
        this.reset()
        next()
    },
    methods: {
        async submit(useNextRoute = false) {
            const artistidx = this.postedOfferData?.artistidx
            const nextRoute = this.completePageData?.nextRoute

            if (/^(결혼식|주례없는 결혼식)$/.test(this.postedOfferData.eventType)) {
                const next = await this.checkOtherWeddingOffer()
                if (next) {
                    useCacheStore().setItem(
                        'pageCache:PostRelatedOffer',
                        JSON.stringify(this.postedOfferData)
                    )
                    this.$historyStack.set([
                        '/home',
                        this.$router.resolve(next).route.fullPath,
                    ])
                    this.$historyStack.get()
                    return
                }
            }

            // 이거 사용 안함
            if (useNextRoute && nextRoute) {
                this.$historyStack.set([
                    '/home',
                    this.$router.resolve(nextRoute).route.fullPath,
                ])
                this.$historyStack.get()
                return
            }

            const stack = ['/home'];

            if (artistidx > 0) {
                stack.push(`/artist/profile/${artistidx}`)
            }

            this.$historyStack.set(stack)
            this.$historyStack.get()
        },
        init() {
            const postedOfferData = sessionStorage.getItem('pageCache:PostOffer_Complete')
            this.postedOfferData = postedOfferData ? JSON.parse(postedOfferData) : {}
            this.setGnb()
        },
        reset() {
            this.$store.commit('setGnbTitle')
            this.$store.commit('setGnbHeight')
            sessionStorage.removeItem('pageCache:PostOffer_Complete')
        },
        nextStep() {
            this.submit()

            // switch (this.step) {
            //     case 0: {
            //         if (this.completePageData) {
            //             this.step++
            //         } else {
            //             this.submit()
            //         }
            //         break
            //     }
            //     case 1: {
            //         this.submit(true)
            //         break
            //     }
            // }
        },
        setGnb() {
            if (this.completePageData && this.step === 1) {
                this.$store.commit('setGnbTitle', this.completePageData.gnbTitle)
                this.$store.commit('setGnbHeight')
            } else {
                this.$store.commit('setGnbTitle')
                this.$store.commit('setGnbHeight', 0)
            }
        },
        async checkOtherWeddingOffer() {
            let searchGenre = ''

            if (validGenre('노래', this.postedOfferData.genre)) {
                searchGenre = t_genre_reverse('MC')
            } else if (validGenre('MC', this.postedOfferData.genre)) {
                searchGenre = t_genre_reverse('노래')
            }

            if (!searchGenre) {
                return null
            }

            const req = {
                method: 'post',
                url: `/client/myOffer/0/10`,
                data: {
                    mode: '',
                    proPrice: 'n',
                    nowState: 'ing',
                },
            }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        const offerData: any[] = data.response.offerData || []

                        const isExists = offerData.some(({ genre }) => {
                            return searchGenre === t_genre_reverse(genre)
                        })

                        if (!isExists) {
                            return {
                                name: 'PostRelatedOffer',
                                query: {
                                    mode: searchGenre
                                }
                            }
                        }
                    }

                    return null
                })
                .catch(e => {
                    log.error(e)
                    return null
                })
        }
    },
}
</script>

<style lang="scss">
.PostOfferComplete {
    i {
        font-style: normal;
    }
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.container {
    img {
        display: block;
        margin: auto;
    }
    .PostOfferComplete-noticeTitle {
        font-size: 36px;
        font-weight: 600;
        color: #000;
        text-align: center;
        margin-bottom: 32px;
    }
    .PostOfferComplete-noticeBody {
        font-size: 2.1rem;
        color: #000;
        text-align: center;
        margin-bottom: 50px;
        line-height: 1.3;
        .color-gray {
            color: #a3a3a3;
        }
    }
    .PostOfferComplete-headerSubtitle {
        margin-top: 15px;
        line-height: 30px;
        font-size: 20px;
        color: #b6b6b6;
        white-space: pre-line;
    }
    .PostOfferComplete-headerBody {
        margin-bottom: 46px;
        font-size: 34px;
        line-height: 54px;
        font-weight: 500;
        color: #000;
        white-space: pre-line;
    }
    .PostOfferComplete-recommArtistSummary {
        color: #5a5a5a;
        font-size: 20px;
        white-space: pre-line;
        line-height: 1.5;
    }
    .PostOfferComplete-youtubeId {
        margin-top: 15px;
    }
}
</style>
